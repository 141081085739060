import moment from 'moment';

export const getDaysArrayByMonth = () => {
    let daysInMonth = moment().daysInMonth();
    const arrDays = [];
    while(daysInMonth) {
        const current = moment().add(1, 'days').add(-daysInMonth, 'days').format('YYYY-MM-DD');
        arrDays.push(current);
        daysInMonth--;
    }

    return arrDays;
}
