<template>
  <div class="w-full">
    <div class="border-b">
      <DataTableSearch @handleSearchTable="handleSearch" />
    </div>

    <ListingsMyTasksNew
        :isMyTaskPage="true"
        :boardId="$route.params.id"
        :isPublicRoute="false"
        :searchValue="searchValue"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Listings from '@/components/Board/Listings/Listings';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import { withListings } from '@/mixins/Listings/withListings';
import ListingsMyTasksNew from '@/components/Board/Listings/ListingsMyTasksNew';

export default {
  name: 'MyTaskTable',
  mixins: [withListings],
  components: {
    ListingsMyTasksNew,
    DataTableSearch,
    Listings
  },
  data() {
    return {
      listings: [],
      searchValue:'',
      isSearched: false
    }
  },
  computed: {
    ...mapGetters({
      myListingsTasks: 'myListingsTasks',
      getBoardListings: 'getBoard'
    })
  },
  watch: {
    getBoardListings(newValue) {
      // this.handleSetBreadCrumbItem(newValue.title);
      this.handleSetBreadCrumbItems({ index: 1, name: newValue.title, to: '' });
      this.boardData = newValue;
    }
  },
  mounted() {
    this.handleSetBreadCrumbItems({ index: 2, name: '', to: '' });

    this.handleGetBoard(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchMyTasks: 'fetchMyTasks',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleGetBoard: 'fetchBoard',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    toggleOpen() {
      this.isSearched = false;
    },
    handleSearch(value) {
      this.searchValue=value
      this.isSearched = true;
      this.copyBoardData = JSON.parse(JSON.stringify(this.listings));
      if (value.length) {
        this.copyBoardData.filter(listing => listing['tasks'] = []);
        this.myListingsTasks.filter((listing, index) => {
          listing.tasks.filter((task) => {
            if (task.title.toLowerCase().includes(value) || task.customer?.toLowerCase().includes(value) || task.members?.filter((member) => member.title.toLowerCase().includes(this.searchValue)).length) {
              this.copyBoardData[index].tasks.push(task);
            }
          })
        });
      }
      this.listings = this.copyBoardData;
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
  }
}
</script>

<style scoped>

</style>
