<template>
  <div class="relative flex justify-end items-center" v-click-outside="closeDropdown">
    <button @click.stop="handleOpenDropDown" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none">
      <span class="sr-only">{{ $t('table.openOptions') }}</span>
      <DropDownThreeDots />
    </button>
    <div
        v-if="isOpen"
        class="mx-3 origin-top-right absolute top-6 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="project-options-menu-0"
    >
      <div class="py-1" role="none">
        <router-link
            :to="{ name: 'task-edit', params: { taskId: item.id }}"
            class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
          {{ $t('global.buttons.edit') }}
        </router-link>
<!--        <router-link-->
<!--            :to="{ path: `boards/${boardId}/task/${item.id}`}"-->
<!--            class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">-->
<!--          {{ $t('global.buttons.edit') }}-->
<!--        </router-link>-->
      </div>
      <div class="py-1" role="none">
        <a @click.prevent="handleOpenDeleteItemModal" href="#" class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
          {{ $t('global.buttons.delete') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
  name: 'EditActions',
  components: { DropDownThreeDots },
  data() {
    return {
      isOpen: false
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    boardId: {
      type: Number,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      'default': false
    }
  },
  methods: {
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('handleSelectItem', this.item.id);
    },
    handleOpenDeleteItemModal() {
      this.$emit('handleOpenDeleteItemModal', this.item.id);
      this.isOpen = false;
    },
    closeDropdown() {
      this.isOpen = false;
      this.$emit('handleSelectItem', '');
    }
  }
}
</script>

<style scoped>

</style>
