<template>
  <div v-if="!loading">
    <DataTableSearch @handleSearchTable="handleSearchTable"/>
    <div class="h-ful border-t border-b">
      <div class="px-4 py-5 bg-white">
        <draggable
            :list="boards"
            :group="{ name: 'row' }"
            ghost-class="draggable"
            v-bind="dragOptions"
            @change="handleChange"
        >
          <transition-group type="transition" name="flip-list"
                            class="row wrap justify-space-around mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
            <div v-for="board in boards" :key="board.id" class="row-v">
              <Card :board="board" :allDaysOfMonth="allDaysOfMonth" />
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from '@/components/Board/Card/Card';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import draggable from 'vuedraggable';
import { getDaysArrayByMonth } from '@/helpers/getDaysArrayByMonth';

export default {
  name: 'BoardsList',
  components: {
    DataTableSearch,
    Card,
    draggable
  },
  data() {
    return {
      loading: true,
      data: [],
      boards: [],
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters([
      'getBoardsWithTasks',
      'getIsCreateBoard',
      'getIsCreateBoardMessage'
    ]),
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false
      };
    },
    allDaysOfMonth() {
      return getDaysArrayByMonth();
    }
  },
  mounted() {
    this.handleGetAllBoards();
  },
  watch: {
    getBoardsWithTasks(newValue) {
      this.data = newValue;
    },
    data(newValue) {
      this.boards = newValue;
      this.loading = false;
    },
    getIsCreateBoard(newValue) {
      newValue && this.toastMessage(this.getIsCreateBoardMessage, 'success');
    }
  },
  methods: {
    ...mapActions({
      handleGetAllBoards: 'fetchAllBoards',
      fetchChangeBoardPosition: 'fetchUpdateBoardCardPosition'
    }),
    handleSearchTable(value) {
      this.searchValue = value;
      this.boards = this.data.filter((board) => {
        if (board.title.toLowerCase().includes(value)) {
          return board;
        }
      })
    },
    handleChange(element) {
      const findIndex = this.boards.findIndex(board => board.id === element.moved.element.id);
      const siblingItem = this.boards[findIndex + 1];
      this.fetchChangeBoardPosition({ id: element.moved.element.id, board_id: siblingItem.id, move: 'before' });
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>
.bg-white.overflow-hidden.shadow.rounded-lg.cursor-pointer {
  height: 100%;
}
</style>
