<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="sm:flex sm:items-start w-full">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left  w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900 text-center" id="modal-headline">
              {{header}}
            </h3>
            <div class="mt-2">
               <FormInput
                   :label="$t('label.fields.title')"
                   inputName="title"
                   :value="form.title"
                   :errorMessage="errorForm.title"
                   @handleInputChange="handleInputChange"
               />
              <div class="mt-3 ">
                <p class="text-sm font-medium text-gray-500 mb-1">{{ $t('label.selectColor') }}</p>
                <div class="flex flex-wrap">
                  <p v-for="labelColor in labelColors" :key="labelColor.id" @click="selectColor(labelColor)" class="w-48p h-32p flex items-center justify-center rounded hover:bg-opacity-75 cursor-pointer mr-2 mb-2" :class="labelColor.name">
                  <CheckIcon v-if="selectedColor===labelColor.name"/>
                  </p>
                </div>
                <div class="flex items-center mb-2">
                  <p @click="selectColor(labelDefaultColor)"  class="w-48p h-32p rounded hover:bg-opacity-75 flex items-center justify-center cursor-pointer mr-2 mb-2" :class="labelDefaultColor.name">
                    <CheckIcon v-if="selectedColor===labelDefaultColor.name"/>
                  </p>
                </div>
                <ErrorMessage v-if="errorForm.color" :messages="errorForm.color"/>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <MainButton
            @clickAction="handleCreate"
            :name="$t('label.save')"
          />
          <div class="mr-1">
            <MainButton
                @clickAction="handleCloseModal"
                :name="$t('label.cancel')"
                bgStyle="light"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import FormInput from '../../Main/Form/FormInput/FormInput';
import CheckIcon from '../../Icons/CheckIcon';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage'

export default {
  name: 'LabelCreateModal',
  components: {
    CheckIcon,
    FormInput,
    MainButton,
    ErrorMessage
  },
  props: {
    header: {
      type: String,
      required: true
    },
    title: {
      type: String,
      'default':''
    },
    labelColors:{
      type:Array,
      required: true
    },
    labelDefaultColor: {
      type:Object,
      required:true
    },
    errorForm: {
      type:Object,
      'default': function() {
        return {}
      }
    },
    formData: {
      type:Object,
      'default': function() {
        return {}
      }
    }
  },
  data(){
    return {
      form: {
        title:'',
        color:''
      },
      selectedColor:''
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal');
    },
    handleCreate() {
      this.$emit('handleConfirm', this.form);
    },
    handleInputChange(inputName, value){
      this.form[inputName] = value
    },
    selectColor(value){
      this.selectedColor = value.name
      this.form.color = value.name

    }
  },
  mounted() {
    if (this.formData.id) {
      this.selectedColor = this.formData.color
      this.form.color = this.formData.color
      this.form.title = this.formData.title
    }
  }
}
</script>

<style scoped>

</style>
