<template>
  <div class="h-screen">
    <draggable
        :list="listings"
        v-bind="dragOptions"
        @change="handleChange"
        class="list-group"
    >
      <transition-group type="transition" name="flip-list">
        <template>
          <!--                              <AccordingItem-->
          <!--                                  v-for="listing in listings"-->
          <!--                                  :listing="listing"-->
          <!--                                  :tasks="-->
          <!--                                      $route.name !== 'public-listings'-->
          <!--                                          ? tasks[listing.id]-->
          <!--                                          : listing.tasks-->
          <!--                                  "-->
          <!--                                  :isLoading="listing.loading"-->
          <!--                                  :searchValue="searchValue"-->
          <!--                                  :show="!!focusedTasks[listing.id]"-->
          <!--                                  :key="listing.id"-->
          <!--                                  :boardId="listing.board_id"-->
          <!--                                  :isSearched="isSearched"-->
          <!--                                  :isMyTaskPage="isMyTaskPage"-->
          <!--                                  @toggle="(id, status) => onToggle(id, status)"-->
          <!--                                  :isPublicRoute="isPublicRoute"-->
          <!--                              />-->
          <AccordingItemNew
              v-for="(listing,index) in listings"
              :listing="listing"
              :tasks="taskList"
              :index="index"
              :key="listing.id"
              :showList="showList"
              ref="children"
              :isPublicRoute="isPublicRoute"
              :boardId="boardId"
              :isMyTaskPage="isMyTaskPage"
              :showTable="openedListingId === listing.id"
              @toggle="(id, status) => onToggle(id, status)"
              @deleteItem="deleteListing"
              @handleSort="handleSort"
          />
        </template>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import AccordingItem from '@/components/Board/AccordingItem/AccordingItem';
import AccordingItemNew from '@/components/Board/AccordingItem/AccordingItemNew';
import draggable from 'vuedraggable';
//import { fetchTasksByListing } from '@/services/api/listing';
//import { withListings } from '@/mixins/Listings/withListings';
import { fetchByGroup, fetchSearchedByGroup, fetchTasksByListing } from '@/services/api/listing';
import { fetchTasksByCompany, fetchTasksByMembers, fetchTasksByMyTask } from '@/services/api/task';
import { fetchBoard, fetchChangeListingPosition, fetchSearchedByStatus } from '@/services/api/board';
import { EventBus } from '@/eventBus/event-bus';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ListingsNew',
  // mixins: [withListings],
  components: {
    AccordingItem,
    draggable,
    AccordingItemNew
  },
  props: {

    // listingsArr: {
    //     type: Array,
    //     required: true
    // },
    // myTask: {
    //     type: Boolean,
    //     'default': false
    // },
    // account: {
    //     type: Object,
    //     'default': undefined
    // },
    // searchValue: {
    //     type: String,
    //     'default': ''
    // },
    // isSearched: {
    //     type: Boolean,
    //     required: true
    // },
    searchValue: {
      type: String,
      'default': ''
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      'default': false
    },
    boardId: {
      type: String | Number,
      required: true
    }
  },
  data() {
    return {
      tasks: {
        //
      },
      sortBy: 'title',
      sortDesc: 0,
      groupType: null,
      flag: false,
      mainData: {},
      rootListings: '',
      board_id: null,
      dataTable: {},
      focusedTasks: {
      },
      opened_listing: '',
      taskList: [],
      showList: [],
      listings: [],

      openedListingId: -1

    };
  },
  watch: {
    async searchValue(searchQuery) {
      this.openedListingId = -1;
      await this.searchTasks(searchQuery)
    },
    getCreatedNewListing(value) {
      this.listings.push(value);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false
      };
    },
    ...mapGetters({
      getCreatedNewListing: 'getCreatedNewListing'
    })
  },
  async created() {
    await this.fetchBoard();
  },
  destroyed() {
    EventBus.$off('handleGroupBy')
  },
  async mounted() {
    EventBus.$on('handleGroupBy', async type => {
      this.taskList = [];
      this.groupType = type;
      this.$emit('getGroupType', type)
      if (!type && !this.searchValue) {
        await this.fetchBoard(this.searchValue);
        return;
      }

      if (this.searchValue) {
        await this.searchTasks(this.searchValue);
        return
      }
      const result = await fetchByGroup(this.$route.params.id, type);
      /// view data
      this.listings = result.listings
      const l = this.listings.map((item, index) => {
        return {
          id: item.id,
          index,
          show: false
        }
      })
      this.showList = l;
    });
    this.taskList = [];
    const arr = this.listings.map((item, index) => {
      return {
        id: item.id,
        index,
        show: false
      }
    })
    this.showList = arr;
  },
  methods: {
    async onToggle(id, status) {
      const list = this.showList.map((item, i) => {
        if (item.id === id) {
          return {
            id: item.id,
            index: i,
            show: true
          }
        }
        return {
          id: item.id,
          index: i,
          show: false
        }
      })
      this.showList = list

      this.openedListingId = -1;

      if (!status) {
        return
      }

      this.openedListingId = id;

      this.fetchItems();

    },
    async handleChange(element) {
      const findIndex = this.listings.findIndex(board => board.id === element.moved.element.id);
      const siblingItem = this.listings[findIndex + 1];
      await fetchChangeListingPosition(element.moved.element.id, siblingItem.id, 'before');
    },
    async fetchBoard() {
      const da = await fetchBoard(this.$route.params.id)
      this.listings = da.data.listings;
      await this.$store.dispatch('add_listings', da.data.listings)

      this.showList = this.listings.map((item, index) => {
        return {
          id: item.id,
          index,
          show: false
        }
      })
    },
    searchTasks: _.debounce(async function(searchQuery) {
      if (!this.groupType) {
        const data = await fetchSearchedByStatus(this.$route.params.id, searchQuery);
        this.listings = data.data.listings;
        await this.$store.dispatch('add_listings', data.data.listings)

        this.showList = this.listings.map((item, index) => {
          return {
            id: item.id,
            index,
            show: false
          }
        })
        return;
      }
      const res = await fetchSearchedByGroup(this.$route.params.id, this.groupType, searchQuery)
      this.listings = res.listings;

      this.showList = this.listings.map((item, index) => {
        return {
          id: item.id,
          index,
          show: false
        }
      })
    }, 500),

    deleteListing(id) {
      this.listings = [...this.listings.filter(item => item.id !== id)];
    },

    async fetchItems(clearBefore = true) {
      if (clearBefore) {
        this.taskList = [];
      }

      switch (this.groupType) {
        case 'customer':
          const customer = await fetchTasksByCompany(this.boardId, this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
          this.taskList = customer.data
          break;
        case 'members':
          const members = await fetchTasksByMembers(this.boardId, this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
          this.taskList = members.data
          break;
        case 'myTask':
          const myTask = await fetchTasksByMyTask(this.boardId, this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
          this.taskList = myTask.data
          break;
        default:
          const data = await fetchTasksByListing(this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
          this.taskList = [...data.tasks];
      }
    },

    handleSort(key, sorting) {
      this.sortBy = key;
      this.sortDesc = +sorting;

      this.fetchItems(false);
    }
  }

};
</script>

<style scoped>
</style>

