<template>
  <tr @mouseover="mouseOver(true)" @mouseleave="mouseLeave(false)">
    <td class="hidden xl:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500">
      <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" :src="user.avatar" :alt="user.name">
    </td>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
      <div class="flex items-center space-x-3">
        <router-link :to="{ name: 'user-show', params: { id: user.id } }" class="truncate hover:text-gray-600">
          <span>{{ user.name }}</span>
        </router-link>
      </div>
    </td>
    <td class="px-8 py-3 text-sm text-gray-500 md:table-cell whitespace-nowrap truncate block">
      <span class="pr-6">{{ user.title }}</span>
    </td>
    <td class="hidden xl:table-cell py-3 whitespace-nowrap text-sm text-gray-500 truncate">
      {{ user.email }}
    </td>
    <td class="hidden xl:table-cell py-3 whitespace-nowrap text-sm text-gray-500 truncate px-10">
      {{ user.fennoa_dimension_code }}
    </td>
    <td class="hidden xl:table-cell py-3 whitespace-nowrap text-sm text-gray-500">
      {{ user.lang }}
    </td>

    <td class="pr-4">
      <EditActions
          v-if="showEditable || (selectedItem === String(user.id))"
          :item="user"
          itemType="user"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import TableRowUserAvatar from '@/components/TableRowUserAvatar/TableRowUserAvatar';

export default {
  name: 'Row',
  components: {
    TableRowUserAvatar,
    EditActions
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    selectedItem: String
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(userId) {
      this.$emit('handleOpenDeleteItemModal', userId);
    }
  }
}
</script>

<style scoped>

</style>
