<template>
  <div>
    <table class="border-collapse-table w-full table-fixed">
      <TableHeader @handleSort="handleSort"/>
      <TableBody
          :tasks="listingTasks"
          :isPublicRoute="isPublicRoute"
          :boardId="boardId"
          :isMyTaskPage="isMyTaskPage"
          @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
      />
    </table>
  </div>
</template>

<script>

import handleSortBoardTasksTable from '@/helpers/handleSortBoardTasksTable';
import TableHeader from '@/components/Board/Table/TableHeader/TableHeader';
import TableBody from '@/components/Board/Table/TableBody/TableBody';

export default {
  name: 'Table',
  components: {
    TableBody,
    TableHeader
  },
  props: {
    listingTasks: {
      type: Array,
      required: true
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    boardId: {
      type: Number,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tasks: []
    }
  },
  watch: {
    listingTasks(newValue) {
      this.tasks = this.listingTasks;
    }
  },
  mounted() {
    this.tasks = this.listingTasks;
  },
  methods: {
    handleSort(key, sorting) {
      this.$emit('handleSort', key, sorting);
    }
  }
}
</script>

<style scoped>

</style>
