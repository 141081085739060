<template>
  <div className="w-full my-4" v-if="!loading">
    <DeleteItemModal
        v-if="showModal"
        :title="$t('task.deleteProductModal.title')"
        :description="$t('task.deleteProductModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteTask"
    />
    <div className="border-b">
      <DataTableSearch @handleSearchTable="handleSearch"/>
      <div className="border-b">
        <Table
            :tasks="searchedTasks"
            :isPublicRoute="isPublicRoute"
            :boardId="boardId"
            :isMyTaskPage="isMyTaskPage"
            @handleOpenDeleteLabelModal="handleOpenDeleteItemModal"
        />
      </div>
      <Pagination :count='count' @handlePaginate="handlePaginate"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Pagination from '@/components/Main/Pagination/Pagination';
import handleTableSort from '@/helpers/handleTableSort';
import Table from '@/components/LabelTask/Table/Table';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import LabelCreateModal from '../../../components/Modals/LabelCreateModal/LabelCreateModal';


export default {
  name: 'LabelTasksTable',
  components: {
    LabelCreateModal,
    DataTableSearch,
    Pagination,
    Table,
    DeleteItemModal
  },

  data() {
    return {
      loading: false,
      tasks: [],
      // dataTable: [],
      count: 1,
      showModal: false,
      selectedTaskId: null,
      isPublicRoute: '',
      boardId: 1,
      isMyTaskPage: '',
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters(['labelsTasks']),
    searchedTasks() {
      return this.labelsTasks.tasks?.filter((task) => {
        if (
            !this.searchQuery
            || task.title.toLowerCase().includes(this.searchQuery)
            || task.customer?.name.toLowerCase().includes(this.searchQuery)
            || task.listing.name.toLowerCase().includes(this.searchQuery)
            || task.listing.board.title.toLowerCase().includes(this.searchQuery)
        ) {
          return task;
        }
      }) ?? []
    }
  },
  watch: {
    tasks(newValue) {
      if (newValue) {
        this.count = newValue.length;
        this.dataTable = newValue.slice(0, 30);
      }
    }
  },
  mounted() {
    this.handleSetBreadCrumbItems({ index: 0, to: '/labels' });
    this.handleSetBreadCrumbItems({ index: 1, to: '' });
    const labelId = this.$route.params.id
    this.fetchLabelsTasks(labelId);

  },
  methods: {
    ...mapActions({
      fetchMyTasks: 'fetchMyTasks', handleDeleteTask: 'deleteTask',
      fetchLabelsTasks: 'fetchLabelsTasks',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    handleSearch(value) {
      this.searchQuery = value;
    },
    handlePaginate(page, defaultPageSlice) {
      const copyProducts = [...this.labelsList];
      this.dataTable = copyProducts.slice(page, defaultPageSlice);
    },
    handleSort(key, sorting) {
      handleTableSort(this, key, sorting);
    },
    handleOpenDeleteItemModal(taskId) {
      this.showModal = true;
      this.selectedTaskId = taskId;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteTask() {
      this.handleDeleteTask({ id: this.selectedTaskId }).then(response => {
        this.labelsTasks.tasks = this.labelsTasks.tasks.filter(task => task.id !== this.selectedTaskId);

        this.toastMessage(response.data.message, 'success');
        this.showModal = false;
      });
    },
    toastMessage(message, type) {
      this.$toast.clear();
      this.$toast.open({ message, type })
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
  }
}
</script>

<style scoped>

</style>
