<template>
  <tbody class="bg-white divide-y divide-gray-100">
    <Row
        v-for="user in dataTable"
        :key="user.id"
        :user="user"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteCustomerModal', user.id)"
        @handleSetSelectedItem="handleSetSelectedItem"
        :selectedItem="String(selectedItem)"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import Row from '@/components/User/Table/TableBody/Row/Row';

export default {
  name: 'TableBody',
  components: {
    EditActions,
    Row
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  methods: {
    handleSetSelectedItem(userId) {
      this.selectedItem = userId;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
