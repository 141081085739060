import { getInstance } from '@/configs/axios';





/**
 *
 * @param id
 * @param searchQuery
 * @param sortBy
 * @param sortDesc
 * @returns {Promise<*>}
 */
export const fetchTasksByListing = async (id, searchQuery, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const searchParams = searchQuery!=='' ? `&search=${searchQuery}` : '';
    const { data } = await getInstance().get(`/task/by-listing/${id}?${searchParams}${sortParams}`);

    return data;
}

/**
 *
 * @param board_id
 * @param groupType
 * @param sortBy
 * @param sortDesc
 * @returns {Promise<*>}
 */
export const fetchByGroup= async (board_id, groupType, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const { data } = await getInstance().get(`/task/by-group/${board_id}/${groupType}?${sortParams}`);

    return data;
}


export const
    fetchSearchedByGroup= async (board_id, groupType, searchQuery) => {
    const { data } = await getInstance().get(`/task/by-group/${board_id}/${groupType}`, {
        params: {
            search: searchQuery
        }
    });

    return data;
}
