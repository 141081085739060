<template>
  <tbody class="bg-white divide-y divide-gray-100">
    <Row
        v-for="task in tasks"
        :key="task.id"
        :task="task"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteLabelModal', task.id)"
        @handleOpenEditItemModal="$emit('handleOpenEditLabelModal', task)"
        @handleSetSelectedItem="handleSetSelectedItem"
        :selectedItem="String(selectedItem)"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/LabelTask/EditActions/EditActions';
import Row from '@/components/LabelTask/Table/TableBody/Row/Row';
import { mapGetters } from 'vuex';

export default {
  name: 'TableBody',
  components: {
    EditActions,
    Row
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  // computed: {
  //   ...mapGetters(['myListingsTasks', 'labelsTasks'])
  // },
  data() {
    return {
      selectedItem: ''
    }
  },
  methods: {
    handleSetSelectedItem(labelId) {
      this.selectedItem = labelId;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
