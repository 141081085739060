<template>
  <div class="w-full my-4" v-if="!loading">
    <DeleteItemModal
        v-if="showModal"
        :title="$t('account.deleteUserModal.title')"
        :description="$t('account.deleteUserModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteUser"
    />
    <div class="border-b">
      <DataTableSearch @handleSearchTable="handleSearch" />
      <Table
          @handleSort="handleSort"
          @handleOpenDeleteCustomerModal="handleOpenDeleteUserModal"
          :dataTable="dataTable"
      />
      <Pagination :count=count @handlePaginate="handlePaginate" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Pagination from '@/components/Main/Pagination/Pagination';
import handleTableSort from '@/helpers/handleTableSort';
import Table from '@/components/User/Table/Table';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';



export default {
  name: 'UsersTable',
  components: {
    DataTableSearch,
    Pagination,
    Table,
    DeleteItemModal
  },

  data() {
    return {
      loading: false,
      usersList: [],
      dataTable: [],
      isSearched: false,
      count: 0,
      showModal:false,
      selectedUserId:''

    }
  },
  computed: {
    ...mapGetters(['users'])
  },
  watch: {
    users(newValue) {
      this.usersList = newValue;
      this.loading = false;
    },
    usersList(newValue) {
      if (newValue){
        this.count = newValue.length;
        this.dataTable = newValue.slice(0, 30);
      }

    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    ...mapActions(['getUsers', 'fetchDeleteUser']),
    toggleOpen() {
      this.isSearched = false;
    },
    handleSearch(value) {
      this.isSearched = true;
      this.dataTable = this.usersList.filter((user) => {
        if (user.name.toLowerCase().includes(value) || user.email.toLowerCase().includes(value)|| user.title?.toLowerCase().includes(value)|| user.fennoa_dimension_code?.toLowerCase().includes(value)) {
          return user;
        }
      })
      this.dataTable = this.dataTable.slice(0, 30);
    },
    handlePaginate(page, defaultPageSlice) {
      const copyProducts = [...this.usersList];
      this.dataTable = copyProducts.slice(page, defaultPageSlice);
    },
    handleSort(key, sorting) {
      handleTableSort(this, key, sorting);
    },
    handleOpenDeleteUserModal(userId) {
      this.selectedUserId = userId;
      this.showModal = true;
    },
    handleCloseModal(){
      this.showModal = false;
    },
    deleteUser(){
      this.fetchDeleteUser(this.selectedUserId).then(response => {
        this.toastMessage(response.message, 'success');
      }).catch(error => {
        this.toastMessage(error.message, 'error');
      });
      this.showModal = false;
      this.usersList = this.usersList.filter(user => user.id !== this.selectedUserId);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    }
  }

}
</script>

<style scoped>

</style>
