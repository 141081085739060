import { render, staticRenderFns } from "./TableBody.vue?vue&type=template&id=718dbab3&scoped=true&"
import script from "./TableBody.vue?vue&type=script&lang=js&"
export * from "./TableBody.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718dbab3",
  null
  
)

export default component.exports