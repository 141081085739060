<template>
  <div class="w-full" >
    <div class="border-b">
      <DataTableSearch
          @handleSearchTable="value => (searchValue = value)"
          groupByAction
      />
    </div>
    <!--        <Listings-->
    <!--            :listingsArr="listings"-->
    <!--            :isSearched="isSearched"-->
    <!--            :searchValue="searchValue"-->
    <!--            :account="account"-->
    <!--            :boardId="boardId"-->
    <!--            :isPublicRoute="false"-->
    <!--            @toggleOpen="toggleOpen"-->
    <!--            @handleChange="handleChange"-->
    <!--        />-->
    <ListingsNew
        :isMyTaskPage="false"
        :boardId="boardId"
        :isPublicRoute="false"
        :searchValue="searchValue"
    />
  </div>
</template>

<script>
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
//import Listings from '@/components/Board/Listings/Listings';
import ListingsNew from '@/components/Board/Listings/ListingsNew';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'BoardListings',
  components: {
    //Listings,
    ListingsNew,
    DataTableSearch
  },
  data() {
    return {
      boardData: {},
      boardId: null,
      copyBoardData: {},
     // listings: [],
      searchValue: '',
      isSearched: false,
      groupType:null
    };
  },
  computed: {
    ...mapGetters({
      getBoardListings: 'getBoard',
      getIsCreateBoardListing: 'getIsCreateBoardListing',
      getIsCreateBoardListingMessage: 'getIsCreateBoardListingMessage',
      account: 'account'
    })
  },
  watch: {
    searchValue(newValue) {
    },
    getBoardListings(newValue) {
      // this.handleSetBreadCrumbItem(newValue.title);
      this.handleSetBreadCrumbItems({ index: 1, name: newValue.title, to: '' });
      this.boardData = newValue;
    },
    boardData({ listings }) {
      this.listings = listings;
      this.loading = false;
    },
    'boardData.listings': function(listings) {
      this.listings = listings;
      this.loading = false;
    },
    getIsCreateBoardListing(newValue) {
      newValue && this.toastMessage(this.getIsCreateBoardListingMessage);
    }
  },
  mounted() {
    const { id } = this.$route.params;
    this.boardId = id;
    this.handleSetBreadCrumbItems({ index: 0, hidden: true });
    this.handleGetBoard(id);
  },
  created() {
  },
  methods: {
    ...mapActions({
      //  fetchChangeListingPosition: 'fetchChangeListingPosition',
      handleGetBoard: 'fetchBoard',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    toggleOpen() {
      this.isSearched = false;
    },
    toastMessage(message, type = 'success') {
      this.$toast.open({ message, type });
    },
    handleChange(element) {
      const findIndex = this.listings.findIndex(
          board => board.id === element.moved.element.id
      );
      let siblingItem = this.listings[findIndex + 1],
          position = 'before';
      if (!siblingItem) {
        siblingItem = this.listings[findIndex - 1];
        position = 'after';
      }
      // this.fetchChangeListingPosition({
      //     id: element.moved.element.id,
      //     listing_id: siblingItem.id,
      //     move: position
      // });
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems()
  }
};
</script>
