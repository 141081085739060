<template>
  <div>
    <trend
        :data="[...coords]"
        :gradient="['#71a97d']"
        auto-draw
        smooth
    >
    </trend>
  </div>
</template>

<script>
export default {
  name: 'Chart',
  props: {
    coords: Array,
    'default': Array
  }
}
</script>

<style scoped>

</style>
