<template>
  <thead class="border-t border-gray-200 border-b border-gray-200 bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
  <tr>
    <th class="whitespace-nowrap xxl:w-1/12 2xl:w-300p px-6 py-3">
      <div class="relative flex items-center flex-start pr-6 font-medium">
        {{ $t('table.user.avatar') }}
      </div>
    </th>
    <th class="whitespace-nowrap xxl:w-1/7 2xl:w-300p px-6 py-3">
      <div class="flex items-end cursor-pointer" @click="handleSort('name')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('account.fields.fullName') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'name'" :arrowUp="arrowSortUp['name']" />
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap sm:w-300p xs:w-300p px-8">
      <div class="flex cursor-pointer" @click="handleSort('title')">
        <div class="truncate block relative flex items-center flex-start pr-6 font-medium">
          {{ $t('account.fields.title') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'title'" :arrowUp="!arrowSortUp['title']" />
        </div>
      </div>
    </th>
    <th class="hidden xl:table-cell">
      <div class="flex cursor-pointer" @click="handleSort('email')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('account.fields.email') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'email'" :arrowUp="!arrowSortUp['email']" />
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap hidden xl:table-cell ">
      <div class="flex w-full cursor-pointer" @click="handleSort('fennoa_dimension_code')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('account.fields.dimensionCode') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'fennoa_dimension_code'" :arrowUp="!arrowSortUp['fennoa_dimension_code']" />
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap hidden xl:table-cell ">
      <div class="flex w-full cursor-pointer">
        <div class="relative flex items-center flex-start  font-medium">
          {{ $t('account.fields.language') }}
        </div>
      </div>
    </th>
    <th class="w-40p" />
  </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHead',
  components: {
    ArrowSolidIcon
  },
  data() {
    return {
      arrowSortUp: {
        name: true,
        title: true,
        email: true,
        fennoa_dimension_code: true
      },
      selectedSortingKey: 'name'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    }
  }
}
</script>

<style scoped>

</style>
