<template>
  <tbody class="bg-white divide-y divide-gray-100">
    <Row
        v-for="task in tasks"
        :key="task.id"
        :task="task"
        :isPublicRoute="isPublicRoute"
        :boardId="boardId"
        :selectedItem="String(selectedItem)"
        :isMyTaskPage="isMyTaskPage"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
        @handleSetSelectedItem="handleSetSelectedItem"
    />
  </tbody>
</template>

<script>
import Row from './Row/Row';

export default {
  name: 'TableBody',
  components: { Row },
  props: {
    tasks: {
      type: Array,
      required: true
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    boardId: {
      type: Number,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      members: [],
      selectedItem: ''
    }
  },
  mounted() {
    this.members = this.tasks.members;
  },
  methods: {
    handleSetSelectedItem(productId) {
      this.selectedItem = productId;
    }
  }
}
</script>

<style scoped>

</style>
