import { EventBus } from '@/eventBus/event-bus';
import { mapActions } from 'vuex';

export const withListings = {
    data() {
        return {
            notAssigned: [{
                name: 'Not assigned',
                id: -1,
                board_id: null,
                tasks: []
            }]
        }
    },
    mounted() {
        EventBus.$on('handleGroupBy', type => this.handleGroupBy(type));
    },
    methods: {
        ...mapActions({ fetchChangeListingPosition: 'fetchChangeListingPosition' }),
        // handleSearchTable(value) {
        //   this.isSearched = true;
        //   this.copyBoardData = JSON.parse(JSON.stringify(this.boardData));
        //   if (value.length) {
        //     this.copyBoardData.listings.filter(listing => listing.tasks = []);
        //     this.getBoardListings.listings.filter((listing, index) => {
        //       listing.tasks.filter((task) => {
        //         if (task.title.toLowerCase().includes(value) || task.customer?.toLowerCase().includes(value)) {
        //           this.copyBoardData.listings[index].tasks.push(task);
        //         }
        //       })
        //     });
        //   }
        //   console.log(this.copyBoardData)
        //   this.listings = this.copyBoardData.listings;
        // },
        handleGroupBy(type) {
            if (type) {
                const groupByListings = JSON.parse(JSON.stringify(this.notAssigned));
                this.groupBy(type, groupByListings);
            } else {
                this.listings = this.rootListings
                this.dataTable = this.mainData
            }
        },
        groupBy(key, groupByListings) {
            for(const [key2, value] of Object.entries(this.dataTable)) {
                value.filter(task => {
                    if (!task[key]?.length && key !== 'myTask' && key !== 'customer') {
                        groupByListings[0].tasks.push(task);
                    } else if (key === 'myTask') {
                        this.groupByMyTask(task, groupByListings);
                    } else {
                        this.getSortingType(key, task, groupByListings);
                    }
                });
            }
            groupByListings.map(item => {
                item.board_id = this.listings[0].board_id
                item.tasksCount = item.tasks.length
            })
            this.listings = groupByListings;
            const dataObj = {};
            this.listings.map(listing => {
                dataObj[listing.id] = listing.tasks
            })
            this.dataTable = Object.assign({}, dataObj)
        },
        getSortingType(key, task, groupByListings) {
            switch (key) {
                case 'customer':
                    this.groupByCompany(task, groupByListings)
                    break
                case 'members':
                    this.groupByMembers(task, groupByListings)
                    break
                case 'myTask':
                    this.groupByMyTask(task, groupByListings)
                    break
            }
        },
        groupByMembers(task, groupByListings) {
            task.members.filter(member => {
                const findMember = groupByListings.find(item => item.id === member.id);
                findMember ? findMember.tasks.push(task) : this.pushGroupByListings(groupByListings, member.name, member.id, task);
            })
        },
        groupByCompany(task, groupByListings) {
            const findCustomer = groupByListings.find(item => item.id === (task.customer_id ?? -1));
            findCustomer ? findCustomer.tasks.push(task) : this.pushGroupByListings(groupByListings, task.customerName, task.customer_id, task);
        },
        groupByMyTask(task, groupByListings) {
            task.members.filter(member => {
                if (member.id === this.account.id) {
                    const findTasks = groupByListings.find(item => item.id === member.id);
                    findTasks ? findTasks.tasks.push(task) : this.pushGroupByListings(groupByListings, this.account.name, member.id, task);
                }
            })
        },
        pushGroupByListings(groupByListings, name, id, task) {
            groupByListings.push({ name, id, board_id: this.board_id, tasks: [task] });
        },
        handleChange(element) {
            const findIndex = this.boards.findIndex(board => board.id === element.moved.element.id);
            const siblingItem = this.boards[findIndex + 1];
            this.fetchChangeListingPosition({ id: element.moved.element.id, board_id: siblingItem.id, move: 'before' });
        }
    },
    destroyed() {
        EventBus.$off('handleGroupBy');
    }
};
