<template>
  <tr
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)"
      class="bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 border-collapse-table-tbody">
          <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.checkList') }}</span>
      {{ task.listing.board.title }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      {{ task.listing.name }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-900 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.task') }}</span>
      <router-link  :to="{ name: 'task-show', params: { taskId: task.id }}" class="truncate hover:text-gray-600">
        <span>{{ task.title }}</span>
      </router-link>
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.customer') }}</span>
      {{ task.customer ? task.customer.name : '' }}
    </td>
    <td class="w-full h-47p block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.members') }}</span>
      <div class="flex table-row-user-avatar">
        <div class="flex">
          <TableRowUserAvatar
              v-for="(member, index) in task.members"
              :key="index"
              :avatar="member.avatar ? member.avatar : null"
              :name="member.name ? member.name : null"
              disableName
          />
        </div>
      </div>
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.added') }}</span>
      {{ task.ago }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.due') }}</span>
      {{ task.due | parseDateMain }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('labelTask.checkList') }}</span>
      {{ task.checklist_items_done }}/{{ task.checklist_items_total }}
    </td>
    <td class="hidden lg:table-cell">
      <EditActions
          v-if="showEditable || (selectedItem === String(task.id))"
          :item="task"
          :isMyTaskPage="true"
          :boardId="task.listing.board.id"
          itemType="task"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Board/Table/TableBody/EditActions';
import TableRowUserAvatar from '@/components/TableRowUserAvatar/TableRowUserAvatar';
import moment from 'moment';

export default {
  name: 'Row',
  components: {
    EditActions,
    TableRowUserAvatar
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    isPublicRoute: {
      type: Boolean
    },
    selectedItem: {
      type: String
    },
    boardId: {
      type: Number
    },
    isMyTaskPage: {
      type: Boolean
    }
  },
  data() {
    return {
      members: [],
      hiddenMembers: [],
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(selectedItem) {
      this.$emit('handleOpenDeleteItemModal', selectedItem);
    },
    handleOpenEditItemModal(labelId) {
      this.$emit('handleOpenEditItemModal', labelId);
    },
    setDateValue(value) {
     return moment(value).format('YYYY-MMM-DD');
    }
  }
}
</script>

<style scoped>

</style>
