<template>
  <div v-show="$route.name !== 'public-listings' || listing.tasksCount">
    <DeleteItemModal
        v-if="showModal"
        :title="$t('task.deleteProductModal.title')"
        :description="$t('task.deleteProductModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteTask"
    />
    <DeleteItemModal
        v-if="showListDeleteModal"
        :title="$t('checkList.deleteTaskListModal.title')"
        :description="$t('checkList.deleteTaskListModal.description')"
        @handleCloseModal="handleCloseListModal"
        @handleConfirm="deleteList"
    />
    <div class="rounded-sm">
      <div class="border-b px-5 py-3 flex cursor-pointer justify-between" @click="toggleOpen" v-click-outside="closeRename">
        <div class="flex w-full">
          <div class="mr-3">
            <CollapseTableIcon :arrowUp="!show" :rotate="!show ? 'rotate-90' : 'rotate-180' "/>
          </div>
          <div class="flex w-full  " v-if="disabled">
            <div class="w-full lg:w-auto">
              <div class="focus:outline-none text-left truncate
                            ">
                {{ listing.name }}
              </div>
            </div>
            <div v-if="listing.tasksCount" class="ml-3 ">
                            <span
                                class="inline-flex  px-2.5 py-0.5 rounded-md text-sm  bg-gray-100 font-medium text-gray-500"
                            >
                                {{ searchValue.length > 0 ? (tasks.length ? tasks.length : 0) : listing.tasksCount }}
                            </span>
            </div>
          </div>
          <input
              v-if="!disabled"
              v-model="listingName"
              @keyup.enter="changeListTitle()"
              :disabled="disabled"
              class="focus:outline-none w-full px-2.5 py-0.5 rounded bg-gray-100 "
          />
          <button
              class="px-4 py-2 bg-primary text-white rounded-md text-13 mx-4"
              v-if="!disabled"
              @click="changeListTitle()"
          >
            Rename
          </button>
        </div>

        <EditActions
            v-if="!isPublicRoute"
            :item="listing"
            @handleOpenDeleteItemModal="deleteListing"
            @handleRenameList="renameList"
        />
      </div>
      <div v-if="show && tasks.length && listing.tasksCount" class="border-b">
        <Table
            :listingTasks="tasks"
            :isPublicRoute="isPublicRoute"
            :boardId="boardId"
            :isMyTaskPage="isMyTaskPage"
            @handleSort="handleSort"
            @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
        />
      </div>

      <div v-else-if="isLoading && show" v-show="false" class=" flex justify-center items-center py-8">
        <div class="animate-spin rounded-full h-24 w-24 border-b-2 border-gray-900"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DeleteModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { mapActions } from 'vuex';
import CollapseTableIcon from '@/components/Icons/CollapseTableIcon';
import Table from '@/components/Board/Table/Table';
import EditActions from '@/components/Board/Listings/EditActions';

export default {
  name: 'AccordingItem',
  components: {
    EditActions,
    Table,
    CollapseTableIcon,
    DeleteItemModal,
    DeleteModal
  },
  directives: {
    ClickOutside
  },
  props: {
    listing: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      'default': false
    },
    searchValue: {
      type: String,
      'default': ''
    },
    tasks: {
      type: Array,
      'default': () => []
    },
    isSearched: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      'default': false
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    boardId: {
      type: Number,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      selectedTaskId: null,
      showListDeleteModal: false,
      listingName: '',
      disabled: true
    };
  },
  mounted() {
    this.listingName = this.listing.name;
  },
  methods: {
    ...mapActions({
      handleDeleteTask: 'deleteTask',
      handleDeleteListing: 'fetchDeleteListing',
      handleUpdateListing: 'updateListing'
    }),
    changeListTitle() {
      this.listing.name = this.listingName;
      const body = {
        id: this.listing.id,
        name: this.listingName
      };
      this.handleUpdateListing(body).then(response => {
        this.toastMessage(response.data.message, 'success');
        this.disabled = true;
      });

    },
    toggleOpen(event) {
      if (event.target.closest('button')) {
        return;
      }

      this.$emit('toggle', this.listing.id, !this.show);
    },
    closeDropdown() {
      this.$emit('toggle', 'petrik', false);
    },
    closeRename() {
      this.disabled = true;
    },
    handleOpenDeleteItemModal(taskId) {
      this.showModal = true;
      this.selectedTaskId = taskId;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    handleCloseListModal() {
      this.showListDeleteModal = false;
    },
    deleteTask() {
      this.tasks = this.tasks.filter(task => task.id !== this.selectedTaskId);
      this.handleDeleteTask({ id: this.selectedTaskId }).then(response => {
        this.toastMessage(response.data.message, 'success');
        this.showModal = false;
      });
    },
    deleteList() {
      this.handleDeleteListing(this.listing.id).then(response => {
        this.$store.commit('filterListings', this.listing.id);
        this.toastMessage(response.data.message, 'success');
        this.showListDeleteModal = false;
      });
    },
    toastMessage(message, type) {
      this.$toast.clear();
      this.$toast.open({ message, type });
    },
    deleteListing() {
      this.showListDeleteModal = true;
    },
    renameList() {
      this.disabled = false;
    },
    handleSort(key, sorting) {
      this.$emit('handleSort', key, sorting);
    }
  }
};

</script>

<style scoped>

</style>
