<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="#74787f" width="25"
         class="text-gray-400 group-hover:text-gray-500 mr-1 h-4 w-4">
      <g stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" class="nc-icon-wrapper">
        <rect x="1" y="1" width="12" height="15" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
        <polyline points="17.001 6.619 22.598 8.132 18.684 22.613 7.099 19.481" fill="none" stroke-miterlimit="10"
                  color="color-2"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CardIcon'
}
</script>

<style scoped>

</style>
