<template>
  <thead class="bg-gray-50 border-collapse-table-thead">
    <tr>
      <th class="p-3 task-250-th font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <div class="flex items-center flex-start cursor-pointer" @click="handleSort('title')">
          <div class="relative flex">
            <span>{{ $t('global.headerLists.listTask') }}</span>
            <div class="absolute -right-4">
              <ArrowUp v-if="selectedSortingKey === 'title'" :arrowUp="arrowSorting['title']"/>
            </div>
          </div>
        </div>
      </th>
      <th class="p-3 font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <div class="flex items-center flex-start cursor-pointer" @click="handleSort('customer_name')">
          <div class="relative flex">
            <span>{{ $t('global.headerLists.listCustomer') }}</span>
            <div class="absolute -right-4">
              <ArrowUp v-if="selectedSortingKey === 'customer_name'" :arrowUp="arrowSorting['customer_name']" />
            </div>
          </div>
        </div>
      </th>
      <th class="p-3 font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <span>{{ $t('global.headerLists.listMembers') }}</span>
      </th>
      <th class="p-3 font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <div class="flex items-center flex-start cursor-pointer" @click="handleSort('created_at')">
          <div class="relative flex">
            <span>{{ $t('global.headerLists.listAdded') }}</span>
            <div class="absolute -right-4">
              <ArrowUp v-if="selectedSortingKey === 'created_at'" :arrowUp="arrowSorting['created_at']"/>
            </div>
          </div>
        </div>
      </th>
      <th class="p-3 font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <div class="flex items-center flex-start cursor-pointer" @click="handleSort('due')">
          <div class="relative flex">
            <span>{{ $t('global.headerLists.listDue') }}</span>
            <div class="absolute -right-4">
              <ArrowUp v-if="selectedSortingKey === 'due'" :arrowUp="arrowSorting['due']"/>
            </div>
          </div>
        </div>
      </th>
      <th class="p-3 font-medium text-xs text-left uppercase bg-gray-50 text-gray-500 hidden lg:table-cell">
        <div class="flex items-center flex-start">
          <div class="relative flex">
            <span>{{ $t('global.headerLists.listCheckList')}}</span>
          </div>
        </div>
      </th>
      <th class="w-40p hidden lg:table-cell" />
    </tr>
  </thead>
</template>

<script>
import ArrowUp from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHeader',
  components: {
    ArrowUp
  },
  data() {
    return {
      arrowSorting: {
        title: true,
        customer_name: true,
        created_at: true,
        due: true
      },
      selectedSortingKey: 'title'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSorting[key]);
      this.arrowSorting[key] = !this.arrowSorting[key];
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../sass/mixins.scss';
.border-collapse-table-thead {
  @include responsive-table
}
</style>
