<template>
  <div class="h-screen">
    <draggable
        :list="listings"
        v-bind="dragOptions"
        @change="handleChange"
        class="list-group"
    >
      <transition-group type="transition" name="flip-list">
        <template>
          <AccordingItemNew
              v-for="(listing,index) in listings"
              :listing="listing"
              :tasks="taskList"
              :index="index"
              :key="listing.id"
              :showDef="showDef"
              :showList="showList"
              ref="children"
              :isPublicRoute="isPublicRoute"
              :boardId="boardId"
              :isMyTaskPage="isMyTaskPage"
              @toggle="(id, status) => onToggle(id, status)"
              @deleteItem="deleteListing"
              :showTable="openedListingId === listing.id"
              @handleSort="handleSort"
          />
        </template>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import AccordingItem from '@/components/Board/AccordingItem/AccordingItem';
import AccordingItemNew from '@/components/Board/AccordingItem/AccordingItemNew';
import draggable from 'vuedraggable';
//import { fetchTasksByListing } from '@/services/api/listing';
//import { withListings } from '@/mixins/Listings/withListings';
import { fetchByGroup, fetchSearchedByGroup, fetchTasksByListing } from '@/services/api/listing';
import { fetchTasksByCompany, fetchTasksByMembers, fetchTasksByMyTask } from '@/services/api/task';
import { fetchBoard, fetchChangeListingPosition, fetchSearchedByStatus } from '@/services/api/board';
import { EventBus } from '@/eventBus/event-bus';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { fetchMyTasks, fetchMyTasksListings } from '@/services/api/myTask';

export default {
  name: 'ListingsMyTasksNew',
  // mixins: [withListings],
  components: {
    AccordingItem,
    draggable,
    AccordingItemNew
  },
  props: {

    // listingsArr: {
    //     type: Array,
    //     required: true
    // },
    // myTask: {
    //     type: Boolean,
    //     'default': false
    // },
    // account: {
    //     type: Object,
    //     'default': undefined
    // },
    // searchValue: {
    //     type: String,
    //     'default': ''
    // },
    // isSearched: {
    //     type: Boolean,
    //     required: true
    // },
    searchValue: {
      type: String,
      'default': ''
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      'default': false
    },
    boardId: {
      type: String | Number,
      required: true
    }
  },
  data() {
    return {
      tasks: {
        //
      },
      groupType: null,
      flag: false,
      mainData: {},
      rootListings: '',
      board_id: null,
      dataTable: {},
      focusedTasks: {
      },
      opened_listing: '',
      taskList: [],
      showDef: false,
      showList: [],
      listings: [],
      openedListingId: -1,
      sortBy: 'title',
      sortDesc: 0
    };
  },
  watch: {
    async searchValue(searchQuery) {
      this.openedListingId = -1;
      await this.searchTasks(searchQuery)
    },
    getCreatedNewListing(value) {
      this.listings.push(value);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false
      };
    },
    ...mapGetters({
      getCreatedNewListing: 'getCreatedNewListing'
    })
  },
  async created() {
    await this.fetchBoard();
  },
  methods: {
    async onToggle(id, status) {
      const list = this.showList.map((item, i) => {
        if (item.id === id) {
          return {
            id: item.id,
            index: i,
            show: true
          }
        }
        return {
          id: item.id,
          index: i,
          show: false
        }
      })
      this.showList = list

      this.openedListingId = -1;

      if (!status) {
        return
      }

      this.openedListingId = id;

      this.taskList = [];

      const myTasks = await fetchMyTasks(this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
      this.taskList = myTasks.data
    },
    async handleChange(element) {
      const findIndex = this.listings.findIndex(board => board.id === element.moved.element.id);
      const siblingItem = this.listings[findIndex + 1];
      await fetchChangeListingPosition(element.moved.element.id, siblingItem.id, 'before');
    },
    async fetchBoard() {
      const listings = await fetchMyTasksListings(this.$route.params.id)
      this.listings = [...listings.data];
      await this.$store.dispatch('add_listings', this.listings)

      this.showList = this.listings.map((item, index) => {
        return {
          id: item.id,
          index,
          show: false
        }
      })
    },
    searchTasks: _.debounce(async function(searchQuery) {
      const data = await fetchMyTasksListings(this.$route.params.id, searchQuery);
      this.listings = data.data;
      await this.$store.dispatch('add_listings', data.data.listings)

      this.showList = this.listings.map((item, index) => {
        return {
          id: item.id,
          index,
          show: false
        }
      })
    }, 500),

    deleteListing(id) {
      this.listings = [...this.listings.filter(item => item.id !== id)];
    },
    async handleSort(key, sorting) {
      this.sortBy = key;
      this.sortDesc = +sorting;

      const myTasks = await fetchMyTasks(this.openedListingId, this.searchValue, this.sortBy, this.sortDesc);
      this.taskList = myTasks.data
    }
  }

};
</script>

<style scoped>
</style>

