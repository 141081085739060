<template>
    <div class="h-screen">
        <draggable
            :list="listings"
            v-bind="dragOptions"
            @change="$emit('handleChange', $event)"
            class="list-group"
        >
            <transition-group type="transition" name="flip-list">
                <template v-if="!!listings.length">
                    <AccordingItem
                        v-for="listing in listings"
                        :listing="listing"
                        :tasks="
                            $route.name !== 'public-listings'
                                ? tasks[listing.id]
                                : listing.tasks
                        "
                        :isLoading="listing.loading"
                        :searchValue="searchValue"
                        :show="!!focusedTasks[listing.id]"
                        :key="listing.id"
                        :boardId="listing.board_id"
                        :isSearched="isSearched"
                        :isMyTaskPage="isMyTaskPage"
                        @toggle="(id, status) => onToggle(id, status)"
                        :isPublicRoute="isPublicRoute"
                        @handleSort="handleSort"
                    />
                </template>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
import AccordingItem from '@/components/Board/AccordingItem/AccordingItem';
import draggable from 'vuedraggable';
import { fetchBoardTasks } from '@/services/api/board';
import { withListings } from '@/mixins/Listings/withListings';

export default {
    name: 'Listings',
    mixins: [withListings],
    components: {
        AccordingItem,
        draggable
    },
    props: {
        listingsArr: {
            type: Array,
            required: true
        },
        myTask: {
            type: Boolean,
            'default': false
        },
        account: {
            type: Object,
            'default': undefined
        },
        searchValue: {
            type: String,
            'default': ''
        },
        isSearched: {
            type: Boolean,
            required: true
        },
        isPublicRoute: {
            type: Boolean,
            required: true
        },
        isMyTaskPage: {
            type: Boolean,
            'default': false
        },
        boardId: {}
    },
    data() {
        return {
            tasks: {
                //
            },
            flag: false,
            listings: [],
            mainData: {},
            rootListings: '',
            board_id: null,
            dataTable: {},
            focusedTasks: {
                //
            },
            opened_listing: ''
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 300,
                group: 'description',
                disabled: false
            };
        }
    },
    watch: {
        searchValue(newValue) {
            if (this.myTask) {
                if (newValue) {
                    this.listings = this.listingsArr;
                    this.getData();
                }
            } else {
                this.filterBySearch();
            }
        },
        listingsArr(listings) {
            this.listings = listings;
        }
    },
    async mounted() {
        this.listings = this.listingsArr;
        if (!this.myTask) {
            await this.getData();

            if (this.$route.name !== 'public-listings') {
                const { data: tasks } = await fetchBoardTasks(this.boardId);
                const listing_tasks = {};

                for(const task of (tasks ?? [])) {
                    if (!listing_tasks[task.listing_id]) {
                        listing_tasks[task.listing_id] = [];
                    }

                    listing_tasks[task.listing_id].push(task);
                }

                for(const listing_id in listing_tasks) {
                    if (true) {
                        this.$set(this.dataTable, listing_id, listing_tasks[listing_id]);
                        this.$set(this.mainData, listing_id, listing_tasks[listing_id]);

                        this.tasks = { ...this.dataTable };
                    }
                }
            }
        } else {
            await this.getMyTaskData();
        }
    },
    methods: {
        getData() {
            this.rootListings = this.listings;
            this.flag = true;
            if (this.searchValue.length > 0) {
                this.filterBySearch();
            } else {
                this.tasks = { ...this.dataTable };
            }
        },
        getMyTaskData() {
            this.listings.forEach(item => {
                this.$set(this.dataTable, item.id, item.tasks);
                this.$set(this.tasks, item.id, item.tasks);
            });
            this.flag = true;
        },
        async onToggle(id, status) {
            const listing_index = this.listings.findIndex(
                item => item.id === id
            );
            this.listings[listing_index].loading = true;

            this.opened_listing = id;
            this.$set(this.focusedTasks, id, status);
            if (this.myTask) {
                this.$set(this.tasks, id, this.listings[listing_index].tasks);
            } else {
                if (this.searchValue.length > 0) {
                    this.filterBySearch();
                } else {
                    this.tasks = { ...this.dataTable };
                }
            }

            this.listings[listing_index].loading = false;
        },
        filterBySearch() {
            for(const [key, value] of Object.entries(this.dataTable)) {
                const data = this.dataTable[key].filter(item => {
                    const members = item.members
                        .map(member => member.title)
                        .join('  #__#  ')
                        .toLowerCase();
                    if (
                        (item.title &&
                            item.title
                                .toLowerCase()
                                .includes(this.searchValue)) ||
                        (item.customer &&
                            item.customer
                                .toLowerCase()
                                .includes(this.searchValue)) ||
                        (members && members.includes(this.searchValue))
                    ) {
                        return item;
                    }
                });
                this.$set(this.tasks, key, data);
            }
        },
        handleSort(key, sorting) {
          this.$emit('handleSort', key, sorting);
        }
    }
};
</script>

<style scoped>
</style>

