<template>
  <div class="align-middle inline-block min-w-full border-b border-gray-200">
    <table class="w-full table-fixed">
      <TableHead @handleSort="handleSort" />
      <TableBody
          :tasks="tasks"
          @handleOpenDeleteLabelModal="$emit('handleOpenDeleteLabelModal', $event)"
          @handleOpenEditLabelModal="$emit('handleOpenEditLabelModal', $event)">
      </TableBody>
    </table>
  </div>
</template>

<script>
import TableHead from '@/components/LabelTask/Table/TableHead/TableHead';
import TableBody from '@/components/LabelTask/Table/TableBody/TableBody';

export default {
name: 'Table',
  components: {
    TableHead,
    TableBody
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSort(key, sorting) {
      this.$emit('handleSort', key, sorting);
    }
  }
}
</script>

<style scoped>

</style>
