<template>
  <div class="h-full border-t border-b">
    <div class="md:grid md:grid-cols-1 md:gap-6">
      <form ref="form" @submit="handleSubmit">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-1">
              <div class="sm:col-span-1"  >
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('account.fields.fullName')" :name="form.name" />
                </div>
                <FormInput
                    v-else
                    :label="$t('account.fields.fullName')"
                    inputName="name"
                    :value="form.name"
                    :errorMessage="formErrors.name"
                    @handleInputChange="handleInputChange"
                />
              </div>
            </div>
            <div class="sm:col-span-1">
              <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('account.fields.title')" :name="form.title" />
                </div>
                <FormInput
                    v-else
                    :label="$t('account.fields.title')"
                    inputName="title"
                    :value="form.title"
                    :errorMessage="formErrors.title"
                    @handleInputChange="handleInputChange"
                />
              </div>
            </div>
            <div class="sm:col-span-1">
              <div v-if="!editable" class="fieldTitle">
                <MainFieldTitle :label="$t('account.fields.password')" :name="form.password" />
              </div>
              <FormInput
                  v-else
                  :label="$t('account.fields.password')"
                  inputName="password"
                  :value ='form.password'
                  :errorMessage="formErrors.password"
                  type="password"
                  @handleInputChange="handleInputChange"
              />
            </div>
            <div class="sm:col-span-1">
              <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('account.fields.email')" :name="users.email" />
                </div>
                <FormInput
                    v-else
                    :label="$t('account.fields.email')"
                    inputName="email"
                    :value="form.email"
                    :errorMessage="formErrors.email"
                    @handleInputChange="handleInputChange"
                />
              </div>
            </div>
            <div class="sm:col-span-1">
              <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('account.fields.dimensionCode')" :name="form.fennoa_dimension_code" />
                </div>
                <FormInput
                    v-else
                    :label="$t('account.fields.dimensionCode')"
                    inputName="fennoa_dimension_code"
                    :value="form.fennoa_dimension_code"
                    :errorMessage="formErrors.fennoa_dimension_code"
                    @handleInputChange="handleInputChange"
                />
              </div>
            </div>
            <div class="sm:col-span-1">
              <div class="grid grid-cols-1 gap-x-4 gap-y-8">
                <div class="sm:col-span-1">
                  <div v-if="!editable" class="fieldTitle">
                    <MainFieldTitle :label="$t('account.fields.language')" :name="form.lang === 'en' ? 'English' : 'Suomi'" />
                  </div>
                  <LanguageOptions
                      v-else
                      :language="form.lang"
                      @handleInputChange="handleInputChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LanguageOptions from '../../../components/SelectOptions/LanguageOptions/LanguageOptions';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import AppLayout from '@/components/Layout/AppLayout';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { EventBus } from '@/eventBus/event-bus';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import GoogleLogin from 'vue-google-login';

export default {
  name: 'UserUpdate',
  components: {
    MainFieldTitle,
    FormInput,
    AppLayout,
    LanguageOptions,
    ErrorMessage,
    GoogleLogin
  },
  data() {
    return {
      editable: true,
      form: {
        name: '',
        password: '',
        title: '',
        fennoa_dimension_code: '',
        lang: 'fi',
        email: ''
      },
      formErrors: {
        name: [],
        title: [],
        fennoa_dimension_code: [],
        email: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'userResponseMessage',
      'updateUserError',
      'updateAvatarError',
      'updateAvatarResponseMessage',
      'updateUserSuccess',
        'users', 'user'
    ])
  },
  watch: {
    updateUserError(newValue) {
      newValue && this.handleRequestErrorMessage(newValue);
    },
    updateUserSuccess(newValue) {
      newValue && this.redirectUrl();
    },
    user(val){
      this.form.title = val.title
      this.form.name = val.name
      this.form.email = val.email
      this.form.lang = val.lang
      this.form.password = val.password
      this.form.fennoa_dimension_code = val.fennoa_dimension_code
    }
  },
  async mounted() {
    await this.handleSetBreadCrumbItems({ index: 0, to: '/users' });
    const { id } = this.$route.params;
    if (id) {
      this.loading = true;
      this.pageUpdateAction = true;
      this.getUser(id);
    }
    EventBus.$on('handleSaveForm', () => this.handleSubmit());
  },
  methods: {
    ...mapActions({
      updateUser: 'updateUser',
      getUser: 'getUser',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
    },
    handleSubmit() {
      const { id } = this.$route.params;
      this.updateUser({ id, form:this.form });
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    },
    handleRequestErrorMessage(isError) {
      if (isError) {
        const { message, errors } = this.userResponseMessage;
        this.formErrors = { ...this.formErrors, ...errors };
        this.toastMessage(message, 'error');
      }
    },
    redirectUrl(){
      this.$router.push({ path: '/users' });
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
    EventBus.$off('handleSaveForm');
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style>
.abcRioButton:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
}
</style>
