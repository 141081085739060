import groupByBoardTasks from '@/helpers/groupByBoardTasks';

export default {
    methods: {
        getTasks() {
            const groupByTasks = groupByBoardTasks(this.board.tasks, 'date');
            this.allDaysOfMonth.map(item => this.taskCoords.push({ date: item, value: 0 }));
            this.taskCoords.filter((taskCoordinates) => {
                let counter = 0;
                if (Object.keys(groupByTasks).includes(taskCoordinates.date)) {
                    groupByTasks[taskCoordinates.date].map(() => {
                        counter++;
                        taskCoordinates.value = counter
                    })
                }
            });
        }
    }
}
