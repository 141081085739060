<template>
  <tr
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)"
      class="bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 border-collapse-table-tbody">
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-900 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Task</span>
      <router-link v-if="!isPublicRoute && !isMyTaskPage" :to="{ name: 'task-show', params: { taskId: task.id }}" class="truncate hover:text-gray-600">
        <span> {{task.title}} </span>
      </router-link>
      <router-link v-if="!isPublicRoute && isMyTaskPage" :to="{ path: `/board/${boardId}/task/${task.id}`}" replace class="truncate hover:text-gray-600">
        <span>{{ task.title}}</span>
      </router-link>
      <router-link v-if="isPublicRoute && !isMyTaskPage" :to="{ name: 'public-task-show', params: { uuid: $route.params.uuid, taskUuid: task.uuid }}" class="truncate hover:text-gray-600">
        <span>{{ task.title}}</span>
      </router-link>
    </td>
    <p class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('table.customer') }}</span>
      <template v-if="isPublicRoute || isMyTaskPage">
        {{ task.customer ? task.customer : ''  }}
      </template>
      <template v-else>
        {{ task.customer ? task.customer.name : ''  }}
      </template>
    </p>
    <td class="w-full h-47p block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('table.members') }}</span>
      <div class="flex table-row-user-avatar">
        <div class="flex">
          <TableRowUserAvatar
              v-if="members && members.length > 0"
              v-for="(member,i) in members"
              :key="i"
              :avatar="member.avatar ? member.avatar : null"
              :name="member.name ? member.name : null"
              disableName
          />
          <span v-if="hiddenMembers.length" class="flex-shrink-0 text-xs leading-5 font-medium">+{{ hiddenMembers.length }}</span>
        </div>
      </div>
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('table.added') }}</span>
      {{ task.ago }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('table.due') }}</span>
      {{ task.due | parseDateMain }}
    </td>
    <td class="w-full h-47p truncate block border-b border-gray-100 lg:w-auto p-3 text-sm font-medium text-gray-500 md:text-center lg:text-left lg:table-cell relative lg:static">
      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{ $t('table.checkList') }}</span>
      {{ task.checklist_items_done }}/{{ task.checklist_items_total }}
    </td>
    <td v-if="!isPublicRoute" class="hidden lg:table-cell">
      <EditActions
          v-if="showEditable || (selectedItem === String(task.id))"
          :item="task"
          :isMyTaskPage="isMyTaskPage"
          :boardId="boardId"
          itemType="task"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import TableRowUserAvatar from '@/components/TableRowUserAvatar/TableRowUserAvatar';
import EditActions from '@/components/Board/Table/TableBody/EditActions';
export default {
  name: 'Row',
  components: {
    EditActions,
    TableRowUserAvatar
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    isPublicRoute: {
      type: Boolean,
      required: true
    },
    selectedItem: {
      type: String,
      required: true
    },
    boardId: {
      type: Number,
      required: true
    },
    isMyTaskPage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      members: [],
      hiddenMembers: [],
      showEditable: false,
      isOpenEditAction: false
    }
  },
  mounted() {
    if (this.task.members){
      if (this.task.members.length <= 3) {
        this.members = this.task.members;
      } else {
        this.members = this.task.members.splice(0, 3);
        this.hiddenMembers = this.task.members;
      }
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(taskId) {
      this.$emit('handleOpenDeleteItemModal', taskId);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../sass/mixins.scss';
.border-collapse-table-tbody {
  @include responsive-table
}
</style>
